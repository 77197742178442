<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>SERVE Student Portal</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <serve-detail v-if="id" :id="id" />
    </v-card-text>
  </v-card>
</template>
<script>
import { ref, onMounted, computed } from '@vue/composition-api'

export default {
  components: {
    ServeDetail: () => import('@/components/serve/detail')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const id = ref('')

    onMounted(async () => {
      if (user.value.roles.includes('Employee')) {
        if (user.value.roles.includes('Student Development') || user.value.roles.includes('Technology Services')) {
          root.$router.replace('/serve/admin')
        } else {
          root.$router.replace('/serve/super')
        }
      } else {
        const { data } = await root.$feathers.service('serve/student').find({ query: { bannerId: user.value.bannerId } })
        if (data.length === 0) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'No entry was found for you in SERVE. Please contact Student Development if you should be in the SERVE system.' })
        } else {
          id.value = data[0]._id
        }
      }
    })

    return {
      id
    }
  }
}
</script>
